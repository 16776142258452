html,
body {
  font-family: 'Gilroy';
  font-style: normal;
  font-size: 14px;
  color: $base-white-color-1;
  background-color: $base-grey-color-0;
}

a {
  text-decoration: none;
  color: $base-white-color-1;
}

a:hover {
  text-decoration: underline;
  color: $base-white-color-1;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.color-df {
  color: $base-white-color-0 !important;
}

.color-f5 {
  color: $base-white-color-1 !important;
}

.overflow {
  overflow: hidden !important;
}

.tac {
  text-align: center;
}

.bi-chevron-down {
  transition: 0.2s all;
  width: 24px;
  height: 24px;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s all;
}

.mxw90 {
  max-width: 90%;
}

@media screen and (max-width: 767px) {
  .logo {
    display: none;
  }
  .big-star {
    transform: scale(0.7);
  }
}
