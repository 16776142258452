.first-screen {
  .background-video {
    background: url('../images/gts.gif') no-repeat center;
    background-size: cover;
    height: calc(100vh - 80px);
    width: 100%;
  }

  .box-shadow {
    width: 100%;
    box-shadow: 0px 0px 120px 150px #171717;
    z-index: 0;
  }

  .mask {
    width: 100%;
    height: calc(100vh - 80px);
    background-color: #171717ad;
  }

  .star {
    top: 0px;
    right: -150px;
    opacity: 0.8;
    width: 400px;
    transform: rotate(45deg);
    z-index: 1;
    opacity: 0.5;
  }

  .big-star {
    bottom: -250px;
    left: -150px;
    opacity: 0.3;
    width: 500px;
    z-index: 1;
  }

  .content {
    text-align: center;
    z-index: 1;

    .logo {
      max-width: 280px;
    }

    .main-title {
      text-transform: uppercase;
    }
  }

  .moving-mouse-holder {
    z-index: 2;
    margin: auto;
    height: 100px;
    animation: mouse-slide-up 1.5s;

    & .mouse {
      width: 26px;
      height: 40px;
      position: relative;
      right: 0;
      border-radius: 18px;
      border: 2px solid $base-white-color-0;
    }

    & .mouse-button {
      background-color: $base-white-color-0;
      width: 4px;
      height: 10px;
      border-radius: 2px;
      position: absolute;
      top: 5px;
      left: 50%;
      margin: 0 0 0 -2px;
      animation: mouse-scroll 0.7s infinite alternate;
    }

    .arrow {
      .left {
        top: 0;
        left: 1px;
        width: 15px;
        height: 3px;
        background-color: $base-white-color-0;
        border-radius: 10px;
        transform: rotate(45deg);
      }

      .right {
        top: 0;
        left: 10px;
        width: 15px;
        height: 3px;
        background-color: $base-white-color-0;
        border-radius: 10px;
        transform: rotate(-45deg);
      }
    }
  }
}

@keyframes mouse-slide-up {
  from {
    transform: translate(0, 200px);
  }
}

@keyframes mouse-scroll {
  to {
    transform: translate(0, 4px) scale(1, 0.8);
  }
}
