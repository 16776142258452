.services {
  min-height: 100vh;
  z-index: 1;

  .card-custom {
    text-align: center;
    z-index: 1;
  }
}

.star-2 {
  bottom: 100px;
  right: -150px;
  opacity: 0.3;
  width: 400px;
  z-index: 0;
}
