.grey {
  transition: 0.2s all;
  cursor: pointer;
  &:hover {
    transition: 0.2s all;
    transform: scale(1.03);
    position: relative;
    z-index: 10;
  }
}
