// Base Colors
$base-grey-color-0: #171717;
$base-grey-color-1: #464646;

$base-white-color-0: #dfdfdf;
$base-white-color-1: #f5f5f5;

$base-cyan-color: #086b6b;

// Bootstrap margings and paddings
$spacer: 5px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    5: (
      $spacer * 1,
    ),
    10: (
      $spacer * 2,
    ),
    15: (
      $spacer * 3,
    ),
    20: (
      $spacer * 4,
    ),
    25: (
      $spacer * 5,
    ),
    30: (
      $spacer * 6,
    ),
    35: (
      $spacer * 7,
    ),
    40: (
      $spacer * 8,
    ),
    45: (
      $spacer * 9,
    ),
    50: (
      $spacer * 10,
    ),
    75: (
      $spacer * 15,
    ),
    100: (
      $spacer * 20,
    ),
    125: (
      $spacer * 25,
    ),
    150: (
      $spacer * 30,
    ),
    200: (
      $spacer * 40,
    ),
    250: (
      $spacer * 50,
    ),
    300: (
      $spacer * 60,
    ),
  ),
  $spacers
);

// Font Sizes fs-x
$interval: 2;
@for $i from 0 through 40 {
  .fs-#{10 + $interval * $i} {
    font-size: #{10 + $interval * $i}px !important;
  }
}

// Bootstrap grid
@import 'bootstrap/scss/bootstrap-reboot.scss';
@import 'bootstrap/scss/bootstrap-grid';

// AOS
@import 'aos/src/sass/aos';

// Fonts
@import 'fonts';

// Own styles
@import 'loader';
@import 'header';
@import 'firstScreen';
@import 'services';
@import 'tile';
@import 'carousel';
@import 'footer';

// Base styles
@import 'base';
