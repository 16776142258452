.header {
  height: 80px;
  z-index: 10;

  .header-logo {
    width: 120px;
    z-index: 20;
  }
  .spinning-gear {
    width: 25px;
    top: 4px;
    left: 82px;
    animation: gear-rotate 1.5s infinite;
  }

  .burger-wrapper {
    right: 30px;
    width: 28px;
    height: 29px;
    transition: 0.2s all;
    cursor: pointer;

    .mobile-menu-one {
      position: absolute;
      top: 0;
      width: 28px;
      height: 3px;
      background-color: $base-white-color-1;
      transition: 0.2s all;
      border-radius: 10px;
      &.opened {
        transform: rotate(45deg);
        top: 13px;
      }
    }

    .mobile-menu-two {
      margin-top: 13px;
      width: 22px;
      height: 3px;
      background-color: $base-white-color-1;
      transition: 0.2s all;
      border-radius: 10px;
      &.opened {
        transition: 0.2s all;
        opacity: 0;
      }
    }

    .mobile-menu-three {
      position: absolute;
      bottom: 0;
      width: 28px;
      height: 3px;
      background-color: $base-white-color-1;
      transition: 0.2s all;
      border-radius: 10px;
      &.opened {
        transform: rotate(-45deg);
        bottom: 13px;
      }
    }
  }
}

.link {
  text-decoration: none;
  color: $base-white-color-0;
  transition: 0.2s all;
  &:hover {
    transition: 0.2s all;
    color: $base-cyan-color;
    text-decoration: none;
  }
}

@keyframes gear-rotate {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .menu-items {
    right: 0;
    bottom: -30px;
  }
}
