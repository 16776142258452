.modal-content {
  background-color: $base-grey-color-0;
  border-radius: 10px;
  margin: 10px;
}

.modal-dialog {
  max-width: 1000px !important;
}

.modal-header {
  border-bottom: none;
}

.modal-body {
  padding: 0;
}

.close {
  color: $base-white-color-0;
  text-shadow: 0 1px 0 $base-grey-color-1;
  outline: none !important;
  transition: 0.2s all;
  transform: scale(2);
  &:hover {
    transition: 0.2s all;
    color: $base-cyan-color;
  }
}

.image-size {
  height: 80vh;
}

.card-header {
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background-color: rgb(83, 83, 83);
    transition: all 0.2s;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  transform: scale(2);
}

.grey__color {
  color: #fff;
  text-shadow: 0px 0px 5px #000;
}
